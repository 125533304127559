.App {
  text-align: center;
}

button:hover {
  color: #40E0D0;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header {
  background-color: #fff !important;
  color: #000 !important;
  top: 0 !important;
  position: fixed !important;
}

/* .Layout-content-52, .Layout-content-419 {
  padding-top: 60px !important;
} */

/* .Layout-content-16 .MuiPaper-root-50 .MuiSvgIcon-root-90 {
  background-color: #3fcff3;
} */

.list-page {
  margin-top: 55px;
}

main > div:first-child {
  margin-top: 50px;
}

span.amplify-section-footer-content > div:last-child {
  display: none !important;
}

.consults__showbtn {
  background-color: #3fcff3 !important;
  color: #647073 !important;
}

/* .amplify-a {
  display: none !important;
} */

@media screen and (max-width: 500px) {
  .App {
    font-size: 1rem !important;
  }
}

button[aria-label="Export"] {
    display: none;
}
