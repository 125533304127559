#cool-loader {
  height: 50px;
  width: 50px; 
  border-bottom: 2px solid #000; 
  border-radius: 50%; 
  animation: spin 1s infinite ease-out;
}

@keyframes spin {
  100% {
   transform: rotate(360deg);
  }
}