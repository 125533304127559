



#fullScreen {
  position: relative;
  width: 80%;
  height: 60%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
  margin: auto;
}

.navBar {
  position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
   background-color: transparent !important;
   color: white;
   text-align: center;
}

.mainWindow {
  width: 100%;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
}



@media screen and (max-width:425px){

    .mainWindow {
        width: 100%;
        height: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 8px;
      }
}

.smallWindow{
  position: absolute;
  width: 20%;
  bottom: 10%;
  right: 5%;
  border-radius: 15px;
}

.mainWindowImage{
    width: 100%;
    height: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 8px;

}

.smallWindowImage{
    position: absolute;
    width: 20%;
    bottom: 10%;
    right: 5%;
    border-radius: 15px;

}


#hangup:hover {
  background-color: #aaaaaa79;
  border-radius:80%;
  padding: 3px;
}


#full-window:hover {
  background-color: #aaaaaa79;
  border-radius:50%;
  padding: 5px;
}

@media only screen and (max-width: 500px) { 
  #fullScreen { 
    display: block; 
    margin: 0 auto; 
    height: 100%;
    width: 100%;
  } 
  .mainWindow {
    width: 100%;
    height:90vh;
    object-fit: initial;
 }

.smallWindow{
position: absolute;
width: 20%;
bottom: 10%;
right: 5%;
border-radius: 15px;
}

.mainWindowImage{
width: 100%;
height: auto;
top: 0;
bottom: 0;
left: 0;
right: 0;
border-radius: 8px;
margin-top: 30px;

}

.smallWindowImage{
position: absolute;
top: 10%;
width: 20%;
bottom: 10%;
right: 5%;
border-radius: 15px;
margin-top: 30px;


}
  }

  