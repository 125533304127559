#signIn {
  animation: slideDown 0.4s linear;
  transition: transform 2s ease;
}

@keyframes slideDown {
  0% {
    transform: translate(0, -400px);
  }
}
