@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600);
.App {
  text-align: center;
}

button:hover {
  color: #40E0D0;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

header {
  background-color: #fff !important;
  color: #000 !important;
  top: 0 !important;
  position: fixed !important;
}

/* .Layout-content-52, .Layout-content-419 {
  padding-top: 60px !important;
} */

/* .Layout-content-16 .MuiPaper-root-50 .MuiSvgIcon-root-90 {
  background-color: #3fcff3;
} */

.list-page {
  margin-top: 55px;
}

main > div:first-child {
  margin-top: 50px;
}

span.amplify-section-footer-content > div:last-child {
  display: none !important;
}

.consults__showbtn {
  background-color: #3fcff3 !important;
  color: #647073 !important;
}

/* .amplify-a {
  display: none !important;
} */

@media screen and (max-width: 500px) {
  .App {
    font-size: 1rem !important;
  }
}

button[aria-label="Export"] {
    display: none;
}

* {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  font-family: "Open Sans", sans-serif;
  color: #3fcff3;
  font-size: 1.6rem;
  line-height: 1.6;
}

.chat__messages {
  overflow-y: auto;
  display: flex;

  flex-flow: column nowrap;
  padding: 1rem;
  outline: none;
  height: 35rem;
  margin-top: 1rem;
}

.chat__message {
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 auto;
  align-self: flex-start;
  padding: 0.1rem 1rem;
  margin: 0rem 0rem 2rem 0rem;
  background-color: #fff;
  color: #000;
  border-radius: 0.5rem;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.05);
  max-width: 60%;
}

.chat__message:last-of-type {
  margin-bottom: 0;
}

.chat__message:first-of-type {
  margin-top: 2rem;
}

.chat__message--me {
  align-self: flex-end;
  background-color: #c2dcb4;
}

.chat__message--image {
  width: 20rem;
  height: 20rem; /*  Necessary for auto scrolling. Messes up the mobile view  */
  padding: 0.5rem;
}

.chat__message--doctor--send--image{
  width: 20rem;
  height: 25rem; /*  Necessary for auto scrolling. Messes up the mobile view  */
  padding: 0.5rem;
}

.chat__message--doctor--send--file{
  max-width: 20rem;
  padding: 0.5rem;
}

.chat__message-body {
  width: 100%;
  height: 100%;
  font-size: 1.6rem;
  /* For Firefox */
  white-space: pre-wrap;

  /* For Chrome and IE */
  word-wrap: break-word;
}

.chat__message-text {
  padding-top: .5rem;
}

.chat__message-time {
  font-size: 0.9rem !important;
  color: #202124;
  text-align: right;
  padding-bottom: 0.5rem;

  /* For Firefox */
  white-space: pre-wrap;

  /* For Chrome and IE */
  word-wrap: break-word;
}

.chat__image-link {
  display: block;
  text-decoration: none;
  /* 
  The height and width are simply placeholders for chat images while they are loading, 
  so that the chat__messages element is always auto scrolled to the very bottom during initial loading
  of messages ond/or when a new message is sent. 
  */
  width: 100%;
  height: 100%;
  /* padding: .8rem; */
  cursor: pointer;
}

.chat__image-link:hover {
  color: #fff;
  /* background-color: #fff; */
}

.chat__image-link:focus {
  color: #fff;
}

.chat__image {
  display: block;
  border: 1px solid #fff;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 0;
}

.chat__actions {
  display: flex;
  width: 100%;
  align-items: center;
  background-color: #fff;
}

.chat__message-input {
  resize: none;
  border: 0 none;
  width: 100%;
  height: 100%;
  font-size: 1.6rem;
  padding: 1rem;
  outline: none;
}

.chat__message-upload {
  margin-right: 1rem;
}

.chat__btn {
  background: #fff;
  color: #2ecc71;
  border: 0 none;
  outline: none;
  cursor: pointer;
}

.chat__template-select {
  border: 0 none;
  padding: 8px 15px;
  margin: 0rem 1rem;
}

.chat__image-upload{
  padding-left: 10px;
  padding-right: 10px;
}
/* .app-messenger {
  display: flex;
  flex-direction: column; }
  .app-messenger .main-messenger {
    display: flex;
    height: 100%;
    overflow: hidden; }
    .app-messenger .main-messenger .content {
      flex-grow: 1;
      display: flex;
      flex-direction: column; }
      .app-messenger .main-messenger .content .messages {
        flex-grow: 1; }
      .app-messenger .main-messenger .content .messenger-input {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        height: 100px;
        display: flex;
        flex-direction: row;
        margin-top: 5px; }
        .app-messenger .main-messenger .content .messenger-input .text-input {
          flex-grow: 1; }
          .app-messenger .main-messenger .content .messenger-input .text-input textarea {
            border: 0 none;
            width: 100%;
            height: 100%;
            padding: 8px 15px; }
        .app-messenger .main-messenger .content .messenger-input .template-select {
          border: 0 none;
          padding: 8px 15px;
          margin: 20px 10px; }
        .app-messenger .main-messenger .content .messenger-input .actions button.send {
          background: #2ecc71;
          color: #fff;
          border: 0 none;
          padding: 8px 15px;
          margin: 23px 10px; }

.messages {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  font-size: 16px; }
  .messages .message {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 20px;
    margin: 70px 0; }
    .messages .message .message-user-image img {
      width: 20px;
      height: 20px;
      border-radius: 50%; }
    .message-text img {
      width: 100px;
      height: 100px;}
    .messages .message .message-body {
      padding-left: 10px; }
      .messages .message .message-body .message-text {
        background: #2ecc71;
        color: #fff;
        padding: 10px;
        border-radius: 10px; }
    .messages .message.me {
      justify-content: flex-end; }

.fa {
  font-family: 'FontAwesome' !important; } */

/*# sourceMappingURL=chat.css.map */

@media screen and (max-width: 500px) {
  .chat__message-text {
    font-size: 1rem;
  }

  .chat__message-time {
    font-size: 0.7rem !important;
  }
}

@media screen and (max-width: 800px) {
  .chat {
    margin-top: 5rem;
  }
}

.dashboard__img-logo:hover {
  cursor: pointer;
}

.dashboard__img-logo:hover {
  cursor: pointer;
}

#loading {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

#loading-text {
  font-size: 2rem;
}

#loading-quote {
}

#loading-anim {
  margin-top: 20px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: 2px solid #3fcff3;
  background-color: #3fcff3;
  -webkit-transition: -webkit-transform 0.5s ease-in;
  transition: -webkit-transform 0.5s ease-in;
  transition: transform 0.5s ease-in;
  transition: transform 0.5s ease-in, -webkit-transform 0.5s ease-in;
  -webkit-animation: loadingAnimation 1s infinite;
          animation: loadingAnimation 1s infinite;
}

@-webkit-keyframes loadingAnimation {
  1% {
    /* transform: rotate(0deg); */
    -webkit-transform: translate(0px, 0);
            transform: translate(0px, 0);
  }

  50% {
    /* transform: rotate(360deg); */
    -webkit-transform: translate(50px, 0);
            transform: translate(50px, 0);
  }

  99% {
    /* transform: rotate(0deg); */
    -webkit-transform: translate(-50px, 0);
            transform: translate(-50px, 0);
  }
}

@keyframes loadingAnimation {
  1% {
    /* transform: rotate(0deg); */
    -webkit-transform: translate(0px, 0);
            transform: translate(0px, 0);
  }

  50% {
    /* transform: rotate(360deg); */
    -webkit-transform: translate(50px, 0);
            transform: translate(50px, 0);
  }

  99% {
    /* transform: rotate(0deg); */
    -webkit-transform: translate(-50px, 0);
            transform: translate(-50px, 0);
  }
}

#signIn {
  -webkit-animation: slideDown 0.4s linear;
          animation: slideDown 0.4s linear;
  -webkit-transition: -webkit-transform 2s ease;
  transition: -webkit-transform 2s ease;
  transition: transform 2s ease;
  transition: transform 2s ease, -webkit-transform 2s ease;
}

@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translate(0, -400px);
            transform: translate(0, -400px);
  }
}

@keyframes slideDown {
  0% {
    -webkit-transform: translate(0, -400px);
            transform: translate(0, -400px);
  }
}

.appbar__profile-link {
  color: black;
  text-decoration: none;
}

#cool-loader {
  height: 50px;
  width: 50px; 
  border-bottom: 2px solid #000; 
  border-radius: 50%; 
  -webkit-animation: spin 1s infinite ease-out; 
          animation: spin 1s infinite ease-out;
}

@-webkit-keyframes spin {
  100% {
   -webkit-transform: rotate(360deg);
           transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
   -webkit-transform: rotate(360deg);
           transform: rotate(360deg);
  }
}
.btn:hover {
  color: rgb(100, 225, 241);
  background-color: rgb(99, 95, 95) !important;
  -webkit-transition: color 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: color 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}





#fullScreen {
  position: relative;
  width: 80%;
  height: 60%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
  margin: auto;
}

.navBar {
  position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
   background-color: transparent !important;
   color: white;
   text-align: center;
}

.mainWindow {
  width: 100%;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
}



@media screen and (max-width:425px){

    .mainWindow {
        width: 100%;
        height: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 8px;
      }
}

.smallWindow{
  position: absolute;
  width: 20%;
  bottom: 10%;
  right: 5%;
  border-radius: 15px;
}

.mainWindowImage{
    width: 100%;
    height: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 8px;

}

.smallWindowImage{
    position: absolute;
    width: 20%;
    bottom: 10%;
    right: 5%;
    border-radius: 15px;

}


#hangup:hover {
  background-color: #aaaaaa79;
  border-radius:80%;
  padding: 3px;
}


#full-window:hover {
  background-color: #aaaaaa79;
  border-radius:50%;
  padding: 5px;
}

@media only screen and (max-width: 500px) { 
  #fullScreen { 
    display: block; 
    margin: 0 auto; 
    height: 100%;
    width: 100%;
  } 
  .mainWindow {
    width: 100%;
    height:90vh;
    object-fit: initial;
 }

.smallWindow{
position: absolute;
width: 20%;
bottom: 10%;
right: 5%;
border-radius: 15px;
}

.mainWindowImage{
width: 100%;
height: auto;
top: 0;
bottom: 0;
left: 0;
right: 0;
border-radius: 8px;
margin-top: 30px;

}

.smallWindowImage{
position: absolute;
top: 10%;
width: 20%;
bottom: 10%;
right: 5%;
border-radius: 15px;
margin-top: 30px;


}
  }

  
/*
  Source at https://github.com/tommymarshall/react-multi-step-form
*/

html, body {
    font-family: "Open Sans", Helvetica, arial;
    font-size: 16px;
    line-height: 1.36;
    max-width: 100%;
    min-height: 100%;
  }
  
  body {
    margin: 0;
    padding: 0;
  }
  
  body * {
    box-sizing: border-box;
  }
  
  .container {
    margin: 20px auto;
    max-width: 400px;
    width: 100%;
  }
  
  .btn {
    border-radius: 3px;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    padding: 10px 12px;
    text-align: center;
  }
  .btn.-default {
    background-color: #fff;
    border: 1px solid #ddd;
    color: #4c5e65;
  }
  .btn.-default:hover {
    background-color: #eee;
  }
  .btn.-primary {
    background-color: #2989c6;
    border: 1px solid #2989c6;
  }
  .btn.-primary:hover {
    background-color: #257ab1;
  }
  
  .progress {
    margin-bottom: 20px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  
  .progress-step {
    display: block;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 18px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }
  
  .pull-right {
    float: right;
  }

  .pull-center {
    float: center;
  }
  
  .pull-left {
    float: left;
  }
  
  .form-fields {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .form-fields > li {
      margin-bottom: 20px;
  }
  
  .form-footer {
    border-top: 1px solid #ddd;
    margin-top: 10px;
    padding-top: 20px;
  }
  
  .label {
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  
  label {
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  
  .checkbox label, .radio label {
    cursor: pointer;
    display: inline-block;
    font-weight: normal;
    padding: 1px 12px 1px 30px;
    position: relative;
  }
  
  .checkbox label input, .radio label input {
    left: 0;
    position: absolute;
    top: 0;
  }
  
  input {
    font-size: 16px;
    padding: 8px;
    width: 100%;
  }
  
  select {
    font-size: 16px;
    padding: 8px;
  }

  h3 {
    margin-left: 15px;
  }
  .fileInput {
    border-bottom: 4px solid #d3d3d3;
    border-right: 4px solid #d3d3d3;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    padding: 10px;
    margin: 15px;
    cursor: pointer;
  }
  .imgPreview {
    text-align: center;
    margin: 5px 15px;
    height: 200px;
    width: 200px;
    border-left: 1px solid #808080;
    border-right: 1px solid #808080;
    border-top: 5px solid #808080;
    border-bottom: 5px solid #808080;
  }
  .imgPreview img {
    width: 100%;
    height: 100%;
  }
  .previewText {
    width: 100%;
    margin-top: 20px;
  }
  .submitButton {
    padding: 12px;
    margin-left: 10px;
    background: #fff;
    border: 4px solid #d3d3d3;
    border-radius: 15px;
    font-weight: 700;
    font-size: 10pt;
    cursor: pointer;
  }
  .submitButton:hover {
    background: #efefef;
  }
  .centerText {
    text-align: center;
    width: 500px;
  }
  
  
